#comments {
	@include paddings;

	.comments-list {
		margin-left: 0;
	}
}

#comments-form {
	@include paddings;

	p {
		margin-left: 0 !important;
	}

	input {
		width: 100%;

		@include susy-breakpoint(400px){
			width: 170px;
		}
	}

	textarea {
		width: 100%;

		@include susy-breakpoint(400px){
			max-width: 400px !important;
			width: 350px;
		}

	}
}

#jc h4 {
	@include paddings;
}
