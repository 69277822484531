.wrapper-bread-crumbs{
	@include paddings;
	margin-bottom: 14px;
	width:100%;

	@include susy-breakpoint(920px){
		padding: 0;
	}
}
.wrapper-bread-crumbs ul{
	margin:0;
	padding:0;
}
.wrapper-bread-crumbs li{
	list-style: none;
	display: inline;
	margin:0;
	padding:0;
}

.wrapper-bread-crumbs a{
	text-decoration: none;
	margin-right: 8px;
	font-size: 14px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
}

.wrapper-bread-crumbs span{
	font-size: 14px;
	font-family: "OpenSansCond";
}

.wrapper-bread-crumbs .divider{
	margin-right: 8px;
	color: #f2dfb5;
}

ul.breadcrumb {
 	margin-bottom: 10px;
}
