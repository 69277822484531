.jshop_list_category {
	// @include paddings;

	@include susy-breakpoint(920px){
		padding: 0;
	}
}

.jshop_list_category  .block_item{
	height: auto;
	width: 100%;
	float: left;
	background: url("../images/background-product-index.png") repeat-x;
	border: 1px solid #e9eaea;
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 24px;
	padding: 0 percentage(5 / 920);

	@include susy-breakpoint(450px){
		width: percentage(420 / 920);
		margin-right:  percentage(24 / 920);
	}

	@include susy-breakpoint(600px){
		width: percentage(290 / 920);
		margin-right:  percentage(24 / 920);
	}

	&:nth-child(2n){

		@include susy-breakpoint(450px){
			margin-right: 0;
		}

		@include susy-breakpoint(600px){
			margin-right: 0;
			margin-right:  percentage(24 / 920);
		}
	}

	&:nth-child(3n){

		@include susy-breakpoint(600px){
			margin-right: 0;
		}
	}
}


.jshop_list_category .block_item img{
	width: 100%;
	max-width: 100%;
	height: auto;
	margin: 10px auto;
	display: block;
	border-radius: 8px;
}

.jshop_list_category .label_products{
	float: left;
	width: 100%;
	margin-top: 40px;
}

.jshop_list_category .item_name{
	width: 100%;
	text-align: center;
	padding-bottom: 15px;
}

.jshop_list_category .item_name a{
	font-size: 24px;
	font-family: "OpenSansCond";
	color: #c53613;
	text-decoration: none;
}

.jshop_list_category .block_item:hover{
	box-shadow: 0px 0px 8px 0px rgb( 74, 29, 26 );
}

.jshop_list_category .additionally-info-adress{
	font-size: 18px;
	font-family: "OpenSansCond";
	width: 100%;
	text-align: center;
}

.jshop_list_category .additionally-info-count-mm{
	font-size: 18px;
	font-family: "OpenSansCond";
	margin-top: 20px;
	margin-left: 20px;
}

.jshop_list_category .additionally-info-product{
	font-size: 18px;
	font-family: "OpenSansCond";
}

.jshop_list_category .item_price{
	width: 138px;
	height: 40px;
	float: right;
	margin-top: -44px;
	background: url("../images/background-price-product.png") no-repeat;
	font-size: 19px;
	font-family: "OpenSansCondLightItalic";
	color: rgb( 255, 255, 255 );
	font-style: normal;
	padding-top: 6px;
	text-align: center;
	margin-right: -1px;
}

.jshop_list_category .item_price:hover{
	background: url("../images/background-price-product-hover.png") no-repeat;
}

.jshop_list_category .additionally-info-product{
	margin-left: 20px;
}


.jshop_list_category{
	float: left;
	margin-top: 50px;
}

#mxcpr {
	display:none;
}
