.menu-category-navigation-line{
	display: none;

	@include susy-breakpoint(870px){
		height: 7px;
		background: url('../images/navigation-line.png') repeat-x;
		display: block;
	}
}

.menu-category-navigation-line-background{
	width: 100%;
	background: url('../images/navigation-line.png') repeat-x;
}

.topmenu {
	position: relative;

	&--desktop {

		.wrapper-menu-category{
			display: none;
			@include paddings;
			width: 100%;
			margin-bottom: 20px;

			@include susy-breakpoint(870px){
				display: block;
			}

			@include susy-breakpoint(920px){
				padding: 0;
			}
		}

		.wrapper-menu-category ul {
			@include clearfix;
			margin: 0;
			padding: 0;
			list-style-type: none;
			margin-top: 24px;
		}

		.wrapper-menu-category li ul li {
			margin-left: 0px;
			float: none!important;
			background: none!important;
		}

		.wrapper-menu-category li ul {
			margin-left: 0px;
			margin-top: 0px;
			display: none;
			position: absolute;
			background-color: #312e26;
			border-radius: 4px;
			opacity: 0.9;
			margin-top: 10px;
			padding: 16px;
			z-index: 2;
		}

		.wrapper-menu-category li ul a:hover {
			color: #eed097;
		}

		.wrapper-menu-category li ul a {
			color: #ffffff;
		}

		.wrapper-menu-category li {
			float: left;
			// margin-right:16px;
			margin-right: percentage(10 / 920);

			@include susy-breakpoint(920px){
				margin-right: percentage(14 / 920);
			}

			&:last-child {
				// margin-right: 0;

				a {
					// margin-right: 0;
				}
			}
		}

		.wrapper-menu-category li.parent:hover {
			background: url('../images/trig-menu-hover.png') 90% center no-repeat;
		}


		.wrapper-menu-category a, .wrapper-menu-category span.separator {
			font-size: 18px;
			font-family: "OpenSansCond";
			color: #666666;
			margin-right: 20px;
		}

		.wrapper-menu-category a:hover {
			color: #cd5e4b;
			cursor: pointer;
		}

		.wrapper-menu-category span.separator:hover {
			// color: #cd5e4b;
			cursor: default;
		}

		.wrapper-menu-category span.separator.level-2 {
			color: #fff;
			cursor: default; 
		}

		.wrapper-menu-category span.separator.level-2:hover {
			color: #fff !important;
			cursor: default !important; 
		}		

		.wrapper-menu-category li.active > span.separator:hover {
			color: #666;
			cursor: default; 
		}		

		.wrapper-menu-category a {
			text-decoration: none;
		}

		.item-115, .item-113, .item-168, .item-116, .item-194, .item-120,
		.item-268, .item-269
		{
			display: none;
		}
	}

	&--phone {
		position: absolute;
		top: 0;
		overflow-y: auto;
		z-index: 11;
		height: 100%;
		margin-bottom: 20px;

			@include susy-breakpoint(300px){
				width: span(90%);
				@include pre(10%);
				// @include post(10%);
			}

			@include susy-breakpoint(400px){
				width: span(80%);
				@include pre(20%);
				// @include post(20%);
			}

			@include susy-breakpoint(600px){
				width: span(70%);
				@include pre(30%);
				// @include post(20%);
			}

		.menu-category-navigation-line {
			display: none;
		}

		.wrapper-menu-category {
			position: relative;
			font-family: $font-OSC-2;

			.nav,
			.nav-child {
				list-style: none;
				margin: 0;
				padding: 0;
			}

			.nav {
				padding: 20px 0;

				& > li > a {
					font-size: 18px;
				}
			}

			.nav-child {
				display: block !important;
				height: auto !important;
				padding: 10px 0 !important;

				a {
					font-size: 16px;
				}
			}

			.separator  {
				color: $color-grey;
				font-size: 18px;
				text-transform: uppercase;
				width: 100%;
				font-weight: bold
			}

			a {
				color: white;
				text-transform: uppercase;
				display: inline-block;
				width: 100%;
				text-decoration: none;
				font-weight: bold;

				&:hover {
					text-decoration: underline;
				}
			}

			li {
				padding-left: 20px;
				margin-top: 3px;

				&.parent {
					& > a {

					}
				}
			}
		}

		.item-269 {
			display: none;
		}
	}

	&__button {

		&--on {
			text-align: center;
			background: $color-brown-3;
			color: white;
			padding: 7px 0;
			cursor: pointer;
			text-transform: uppercase;
			font-weight: bold;
			font-family: $font-OSC;
			margin-bottom: 10px;

			&:hover {
				color: $color-red;
			}
		}

		&--off {
			position: fixed;
			right: 20px;
			top: 20px;
			font-size: 0;
			line-height: 0;
			width: 20px;
			height: 20px;
			border: 2px solid white;
			cursor: pointer;
			border-radius: 50%;
			z-index: 11;

			&:after {
				content: '';
				position: absolute;
				display: block;
				width: 20px;
				height: 2px;
				background: white;
				transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				top: 7px;
				right: -2px;
			}

			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 20px;
				height: 2px;
				background: white;
				transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-webkit-transform: rotate(-45deg);
				top: 7px;
				right: -2px;
			}

			&:hover {
				color: $color-red;
				border: 2px solid $color-red;

				&:after,
				&:before {
					background: $color-red;
				}
			}
		}

		@include susy-breakpoint(870px){
			display: none;
		}
	}
}

