.wrapper-content-center-banners .custom{
	// @include clearfix;
	margin: 0 percentage(15 / 920) 20px;
	background: $color-brown-4;
	padding-bottom: 1px;
	// background: url('../images/left-block-background.png') no-repeat;

	@include susy-breakpoint(920px){
		// min-height: 350px;
		margin: -17px 0 0;

		&:after, &:before {
			display: none;
		}
	}
}

.wrapper-content-center-banners .custom h2{
	padding-left: 20px;
	padding-top: 10px;
	background: $color-brown-5;
	margin-bottom: 0;
	margin-top: 0;
	padding-bottom: 10px;

	@include susy-breakpoint(920px){
		margin-top: 14px;
	}
}

h2{
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
}

.wrapper-content-center-banners .apartment{
	width: span(32%);
	display: inline-block;
	text-align: center;

	@include susy-breakpoint(550px){
		width: span(32.7%);
	}

	@include susy-breakpoint(920px){
		text-align: left;
		display: block;
		width: 211px;
		height: 94px;
		margin: 0 auto;
		margin-bottom: 8px;
		position: relative;
		z-index: 1;
	}
}

.wrapper-content-center-banners img{
	position: relative;
	z-index: 1;
	min-height: 60px;
	display: none;

	@include susy-breakpoint(500px){
		top: 13px;
		display: inline;
	}

	@include susy-breakpoint(920px){
		top: 0px;
	}
}

.wrapper-content-center-banners span{
	position: relative;
	display: block;
	z-index: 2;
	font-size: 17px;
	font-family: "OpenSansCond";
	color: rgb( 27, 0, 0 );

	@include susy-breakpoint(500px){
		top: -17px;
	}

	@include susy-breakpoint(920px){
		top: -30px;
		left: 18px;
	}
}

.wrapper-content-center-banners a{
	text-decoration: none;
	display: block;
	background: white;
	margin-bottom: 10px;
	margin-left: 3px;

	@include susy-breakpoint(500px){
		background: none;
		margin-bottom: 0px;
		margin-left: 0px;
	}
}

.wrapper-content-center-banners .apartment:hover{
		background: url('../images/shadow-apartment.png') center bottom no-repeat;

	@include susy-breakpoint(920px){
		background: url('../images/shadow-apartment.png') left bottom no-repeat;
	}
}

.wrapper-content-center-banners{

	@include susy-breakpoint(920px){
		width: percentage(231 / 920);
		float: left;
	}
}
