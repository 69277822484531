// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
	@if $self {
		&,
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	} @else {
		&:hover,
		&:active,
		&:focus {
			@content;
		}
	}
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
	#{$context} & {
		@content;
	}
}

@mixin clearfix {
	&:before,
	&:after	{
		clear: both;
		content: '';
		display: table;
	}
}

@mixin paddings {
	padding: 0 percentage(15 / 920);
}
