.contacts {
	@include clearfix;
	margin-bottom: 20px;

	&__info {
		// @include span(50%);

		@include susy-breakpoint(600px){
			@include span(50%);
			padding-right: 2%;
		}
	}

	&__map {
		// @include span(50%);
		min-height:350px;

		@include susy-breakpoint(600px){
			@include span(50%);
		}
	}
}
