.jshop h1{
	font-family: "OpenSansCond";
	color: #C53613;
	font-size: 32px;
}

.anim-line{
	background-color: #cd5e4b;
	float: left;
	height: 7px;
	position: relative;
}

.main {
	position: static;
}

.navigation-container{

	@include susy-breakpoint(920px){
		// margin-left: 10px!important;
		// margin-top: 130px!important;
	}
}

.curr td{
	text-align: center;
	font-size: 14px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	width: 30px;
}

.curContainer{
	width: 100px;
	margin: 10px auto;
}

.sub_serach{
	width: 130px;
	height: 44px;
	border: none;
	background: url("../images/background-price-buy.png") 100% no-repeat;
	background-color: #ffffff;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 255, 255, 255 );
	text-shadow: 0.5px 0.866px 1px rgb( 0, 1, 1 );
	cursor: pointer;
	float: left;
	text-align: center;
	padding-top: 12px;
	margin-top: 24px;
	margin-left: 16px;
}

#ExtendedFilterContainer101, .results-text{
	display: none;
}

.wrapper-filter-products{
	float: left;
	margin-bottom: 40px;
}

.wrapper-header-block-time{
	text-align: center;
	font-size: 14px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	padding-top: 26px;
}

.content{
	font-family: "OpenSansCond";
	font-size: 16px;
}

.content-picture {

	@include susy-breakpoint(450px){
		min-width: 225px;
		margin-left: 15px;
		float: right;
	}
}

.content-description {

}

.italics{
	font-style: italic;
}

a.tl-booking-button {
	background: #c52013;
	color: #fff;
	text-decoration: none;
	line-height: 41px;
	padding: 0 30px;
	float: right;
	margin: 13px 0 0 0;
	display: inline-block;
	font-family: "OpenSansCond";
}

a.tl-booking-button:hover {
	background: #d66d27;
}

a.tl-booking-button.inner {
	float: initial;
	display: inline-block!important;
	margin: 0;
}

.item-page {
	@include paddings;

	@include susy-breakpoint(920px){
		padding: 0;
	}
}

#chatra {
	z-index: 2 !important;
}

#tl-search-form {
	// width: 100% !important;
}
