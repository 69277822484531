.pagination {
    @include clearfix;
    margin-bottom: 20px;
}
.pagination-start, .pagination-prev, .pagination-next, .pagination-end{
    display: none;
}

.pagination li{
    float: left;
    list-style: none;
    margin-right: 10px;
    font-family: "OpenSansCond";
    font-size: 18px;
}


.pagination li span{
    color: red;
    background: url("../images/background-paginator.png") left bottom no-repeat;
}

.pagination li a{
    text-decoration: none;
    color: black;
}
