.wrapper-footer{
	@include clearfix;
	position: relative;
	background: $color-brown-2;
	width: 100%;
	height: 100%;

	&:before {
		content: "";
		display: block;
		height: 10px;
		background: $color-brown-3;

		@include susy-breakpoint(920px){
			display: none;
		}
	}

	@include susy-breakpoint(920px){
		background: url('../images/footer-background.png') repeat-x;
	}
}

.wrapper-footer-background{
	min-height: 340px;
}

.wrapper-footer-company{
	height: 100%;
	font-size: 17px;
	font-family: "OpenSansCond";

	@include susy-breakpoint(920px){
		@include span(percentage(420 / 920));
	}
}

.wrapper-footer-contact{
	height: 100%;

	@include susy-breakpoint(440px){
		@include span(50%);
	}

	@include susy-breakpoint(920px){
		@include span(percentage(250 / 920));
	}
}

.wrapper-footer-payment{
	height: 100%;

	@include susy-breakpoint(440px){
		@include span(50%);
	}

	@include susy-breakpoint(920px){
		@include span(percentage(250 / 920));
	}
}

.wrapper-footer-company .custom{
	@include paddings;
	padding-top: 20px;
	margin-bottom: 10px;

	@include susy-breakpoint(920px){
		padding-left: 0;
	}
}

.wrapper-footer-contact .custom {
	@include paddings;
	font-size: 17px;
	font-family: "OpenSansCond";

	@include susy-breakpoint(440px){
		padding: 20px 0 20px percentage(15 / 460);
	}

	@include susy-breakpoint(920px){
		padding: 20px 20px 0 20px;
	}
}

.wrapper-footer-contact .custom span{
	color: #666666;
}

.wrapper-footer-contact .custom img{
	margin-right: 6px;
}
.wrapper-footer-contact .last-p {
	margin-bottom:0;
}
.send_director{
	display: block;
	background: url("../images/button-director.png") no-repeat;
	width: 151px;
	height: 44px;
	text-decoration: none;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 255, 255, 255 );
	padding-left: 14px;
	padding-top: 6px;
	margin-top: 18px;
}

.wrapper-footer-payment .custom{
	@include paddings;
	font-size: 17px;
	font-family: "OpenSansCond";

	@include susy-breakpoint(440px){
		padding: 20px percentage(15 / 460) 20px 0;
	}

	@include susy-breakpoint(920px){
		padding: 20px;
	}
}

.wrapper-footer-payment img{
	width: 200px;
	margin-top: 20px;
}
