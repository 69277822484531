.wrapper-filter-products .custom{
	width: 936px;
	height: 59px;
	background: url("../images/background-filter.png") repeat-x;
}

.wrapper-filter-products .custom{
	float: left;
	padding-top: 16px;
}

.wrapper-filter-products .f1 span{
	float: left;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	margin-left: 16px;
}

.wrapper-filter-products .custom .f1 a{
	width: 160px;
	height: 30px;
	float: left;
	font-size: 17px;
	font-family: "OpenSansCond";
	text-decoration: none;
	text-align: center;
	padding-top: 4px;
	margin-left: 14px;
	color: rgb( 27, 0, 0 );
	background: url("../images/background-filter-a.png") 0px top no-repeat;
}

.wrapper-filter-products .custom .f1 a:hover{
	width: 160px;
	height: 30px;
	background: url("../images/background-filter-a.png") -481px top no-repeat;
}

.wrapper-filter-products .f1 a.active{
	width: 160px;
	height: 30px;
	float: left;
	font-size: 17px;
	font-family: "OpenSansCond";
	text-decoration: none;
	text-align: center;
	padding-top: 4px;
	margin-left: 14px;
	color: rgb( 27, 0, 0 );
	background: url("../images/background-filter-a.png") -160px top no-repeat;
}

.wrapper-filter-products .f1 a.active:hover{
	width: 160px;
	height: 30px;
	background: url("../images/background-filter-a.png") -320px top no-repeat;
}


.wrapper-filter-products .f2{
	float: left;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	margin-top: 20px;
	margin-left: 20px;
	width: 260px;
}

.wrapper-filter-products .f3{
	float: left;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	margin-top: 20px;
	margin-left: 20px;
}

.wrapper-filter-products .f3 input{
	width: 62px;
	height: 26px;
	border-style: solid;
	border-width: 1px;
	border-color: rgb( 163, 135, 112 );
	border-radius: 3px;
	font-family: "OpenSansCond";
	font-size: 20px;
	color: rgb( 146, 111, 80 );
	background-color: rgb( 250, 253, 254 );
	padding-left: 4px;
	margin-left: 4px;
}

.wrapper-filter-products .f4{
	float: left;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 146, 111, 80 );
	margin-top: 20px;
	margin-left: 20px;
}

.wrapper-filter-products .f4 select {
	width: 200px;
	border: none;
	height: 32px;		/* Меньше, чем рисованная "кнопочка" на ширину нужного border'а */
	line-height: 22px;
	position: relative;		/* Это нужно, чтобы работал z-index */
	background: none;		/* Через прозрачный фон будет видно кнопку */
	z-index: 100;
	font-family: "OpenSansCond";
	font-size: 20px;
	color: rgb( 146, 111, 80 );
}

.wrapper-filter-products .f4 input {
	border: none;
}

.wrapper-filter-products .select-outer {
	border: rgb( 163, 135, 112 ) 1px solid;
	width: 180px;
	overflow: hidden;
	display: inline-block;
	position: relative;
	border-radius: 4px;
}

.wrapper-filter-products .f4 .select-button {
	background: url('../images/arrow-down-select-count-ubicaciones.png') center center no-repeat;
	height: 24px;		/* Размеры подготовленной картинки */
	width: 24px;
	position: absolute;		/* Размещаем объект по правому краю */
	right: 0;
	top: 4px;
	bottom: 0;
	z-index: 50;		/* И спускаем его на нижний слой - чтобы ссылка */
								/* не перехватывала событие щелчка от select'а */
}

.wrapper-filter-products p{
	color: black!important;
}


.ExtendedFilterContainer{
	float: left;
	margin-top: 50px;
}
