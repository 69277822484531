$susy: (
	flow: 			ltr,
	math: 			fluid,
	output: 		float,
	gutter-position: inside,
	container: 		auto,
	container-position: center,
	columns: 		12,
	gutters: 		0,
	column-width: 	false,
	global-box-sizing: content-box,
	last-flow: 		to
);

// Colors
$color-brown: 		#EED49D;
$color-brown-2: 	#EEDB9E;
$color-brown-3: 	#AF8B46;
$color-brown-4: 	#F3E6BE;
$color-brown-5: 	#EFD7A2;
$color-red: 			#C53613;
$color-dark-alpha: 	rgba(0,0,0,0.7);
$color-grey: 		#b0b0b0;

// Fonts
$font-OSC: 		'Open Sans Condensed',sans-serif;
$font-OSC-2: 	'OpenSansCond', sans-serif;
