.blog {
	@include clearfix;

	.items-row {
		@include clearfix;
		margin-bottom: 20px;
	}

	&__intro-img {
		@include paddings;

		@include susy-breakpoint(500px){
			@include span(4);
			padding-right: 20px;
		}
	}

	&__heading {
		@include paddings;
		margin-top: -40px;
	}

	&__content {
		@include paddings;

		@include susy-breakpoint(500px){
			@include span(8);
		}

		h2 {
			margin-top: 0;
			margin-bottom: 15px;
			font-size: 20px;
			font-family: OpenSansCond;

			a {
				color: #926f50;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

// @include susy-breakpoint(920px){
// 	@include span(percentage(420 / 920));
// }
