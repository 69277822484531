@font-face {
    font-family: "OpenSansCond";
    src: url('../font/OpenSans-CondLight.eot');
    src:  local("OpenSans-CondensedLight"),
    url("../font/OpenSans-CondLight.eot?#iefix") format("embedded-opentype"),
    url("../font/OpenSans-CondLight.ttf") format("truetype"),
    url("../font/OpenSans-CondLight.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "OpenSansBold";
    src: url('../font/OpenSans-Bold.eot');
    src:  local("OpenSans-Bold"),
    url("../font/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../font/OpenSans-Bold.ttf") format("truetype"),
    url("../font/OpenSans-Bold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "OpenSansRegular";
    src: url('../font/OpenSans-Regular.eot');
    src:  local("OpenSans-Regular"),
    url("../font/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../font/OpenSans-Regular.ttf") format("truetype"),
    url("../font/OpenSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "OpenSansCondLightItalic";
    src: url('../font/OpenSans-CondLightItalic.eot');
    src:  local("OpenSans-CondLightItalic"),
    url("../font/OpenSans-CondLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../font/OpenSans-CondLightItalic.ttf") format("truetype"),
    url("../font/OpenSans-CondLightItalic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
