.slider-container img{

	@include susy-breakpoint(920px){
		// height: 350px!important;

	}
}

.wrapper-center-slider > div{
	@include paddings;
	margin-bottom: 10px;

	@include susy-breakpoint(920px){
		padding: 0;
		// width: 670px;
		width: percentage(670 / 920);
		float: left;
		margin-left: 19px;
	}
}

.navigation-container {
	// margin-top: 0 !important;
	// top: 29% !important;
	top: 46% !important;
}

.djslider-default {
	// height: 400px !important;
}
