.wrapper-header-background {
	width: 100%;
	background: url('../images/background-header.png') repeat-x;
	background-size: contain;
}

.wrapper-header-block-logo {
	background: url('../images/logo.png');
	width: 95px;
	height: 87px;
	display: block;
	margin-top: 15px;
}

.wrapper-header-block {
	@include paddings;
	float: left;
	width: 24%;
	min-height: 110px;

	&:nth-child(2){
		display: none;

		@include susy-breakpoint(700px){
			display: block;
			float: left;
			width: 24%;
		}
	}

	&:nth-child(3){
		display: none;

		@include susy-breakpoint(920px){
			display: block;
		}
	}

	 &:last-child {
		width: 210px;
		float: right;
		margin: 0;

		@include susy-breakpoint(400px){
			width: 300px;
		}

		@include susy-breakpoint(920px) {
			width: 30%;
			float: right;
			margin: 0;
		}
	}

	@include susy-breakpoint(500px){
		@include span(33%);
	}

	@include susy-breakpoint(920px) {
		padding: 0;
		width: 23%;
		height: 100%;
	}

	div.mod-languages {
		ul {
			padding-top: 2px;
		}
	}
}

.phone_img{
	width: 44px;
	height: 75px;
	background: url('../images/ico-phone.png') left top no-repeat;
	float: left;
	margin-right: 10px;
	margin-top: 6px;
}

.phone{
	font-family: "OpenSansCond", serif;
	font-size: 22px;
	color: rgb( 146, 111, 80 );
	float:left;

	a {
		color: inherit;
		text-decoration: none;
	}
}

.phone_zakaz{
	font-family: "OpenSansCond", serif;
	font-size: 16px;
	font-weight: normal;
	float:left;
	width:210px;
	margin-top: -20px;
	margin-bottom: 10px;
	margin-left: -10px;

	@include susy-breakpoint(400px){
		margin-top: 0px;
		margin-left: 0px;
	}
}

.phone_zakaz a{
	color: #cd5e4b;
}

.wrapper-header-block-phone{
	margin-top: 25px;
}

.wrapper-header-block-language{
	float: right;
	margin-top: 5px;
}
