.bfClearfix{
	width: 100%;
	// width: 450px;

	@include susy-breakpoint(470px){
		max-width: 450px;
	}
}

.bfQuickMode .controls .control-label{
		float: left;
}

.bfQuickMode input[type=text],
.bfQuickMode textarea,
.bfQuickMode select,
.bfQuickMode .uneditable-input {
	float: left;
	margin:2px 0 0 0;
	padding:2px;
	border:1px solid #aaaaaa;
}

.bfQuickMode input[type=text],
.bfQuickMode textarea {
	width: 94% !important;
	min-width: 94% !important;

	@include susy-breakpoint(470px){
		width: 100% !important;
		min-width: 94% !important;
	}
}

.bfQuickMode textarea {
	margin-bottom: 10px;
}

.bfQuickMode .pull-right {
	margin-bottom: 10px;

	@include susy-breakpoint(470px){
		margin-right: 26px;
	}
}
.bfQuickMode .control-group {
	height:28px;
}
#bfElemWrap294 {
	height: 90px;
} 
#bfElemWrap387, 
#bfElemWrap392,
#bfElemWrap397 {
	display: flex;
}
.bfSubmitButton.button {
	margin-bottom: 64px;
	padding: 4px 16px;
}
#bfLabel458,
#bfLabel467,
#bfLabel472 {
	display: none;
}
#bfLabel392,
#bfLabel397,
#bfLabel387 {
	width: 94%;
	padding-bottom: 16px;
	order: 2;
	margin: 0;
}
#ff_elem387,
#ff_elem397,
#ff_elem392 {
	order: 1;
	margin-top: 4px;
	margin-right: 16px;
	align-self: flex-start;
}