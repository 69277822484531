.wrapper-content {

	.jshop {
		@include paddings;

		@include susy-breakpoint(920px){
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.wrapper-content-products .label_products{
	// @include paddings;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;

	width: 100%;

	@include susy-breakpoint(450px) {
		&:after {
			content: "";
			flex-basis: 47.98765217%;
			max-width: 47.98765217%;
		}
	}

	@include susy-breakpoint(600px) {
		&:after {
			content: "";
			flex-basis: percentage(290 / 920);
			max-width: percentage(290 / 920);
		}
	}

	@include susy-breakpoint(920px){
		padding-left: 0;
		padding-right: 0;
	}
}

.wrapper-content-products .block_item{
	flex-grow: 0;
	flex-shrink: 0;

	@include clearfix;
	width: 100%;
	height: auto;

	background: url("../images/background-product-index.png") repeat-x;
	border: 1px solid #e9eaea;
	border-radius: 8px;
	cursor: pointer;
	margin-bottom: 20px;
	padding: 0 percentage(6 / 920);

	@include susy-breakpoint(450px){
		flex-basis: 47.98765217%;
		max-width: 47.98765217%;
	}

	@include susy-breakpoint(600px) {
		flex-basis: percentage(290 / 920);
		max-width: percentage(290 / 920);
	}
}


.wrapper-content-products .block_item img{
	width: 100%;
	max-width: 100%;
	height: auto;
	margin: 10px auto;
	display: block;
	border-radius: 8px;
}

.wrapper-content-products .item_name{
	width: 100%;
	text-align: center;
	min-height: 70px;
}

.wrapper-content-products .item_name a{
	font-size: 24px;
	font-family: "OpenSansCond";
	color: #c53613;
	text-decoration: none;
}

.wrapper-content-products .block_item:hover{
	box-shadow: 0px 0px 8px 0px rgb( 74, 29, 26 );
}

.wrapper-content-products .additionally-info-adress{
	font-size: 18px;
	font-family: "OpenSansCond";
	width: 100%;
	text-align: center;
}

.wrapper-content-products .additionally-info-count-mm{
	font-size: 18px;
	font-family: "OpenSansCond";
	margin-top: 20px;
	text-align: center;

	@include susy-breakpoint(920px){
		text-align: left;
		margin-left: 10px;
	}
}

.wrapper-content-products .additionally-info-product{
	font-size: 18px;
	font-family: "OpenSansCond";
	text-align: center;

	@include susy-breakpoint(920px){
		text-align: left;
		margin-left: 10px;
	}
}

.wrapper-content-products .item_price{
	width: 155px;
	min-height: 40px;
	font-size: 19px;
	font-family: "OpenSansCondLightItalic";
	color: #c52013;
	font-style: normal;
	padding-top: 6px;
	text-align: center;
	position: relative;
	margin: 0 auto 5px;

	@include susy-breakpoint(920px){
		float: right;
		margin-top: -44px;
		margin-right: -6px;
	}

	.tl-booking-button {
		margin-bottom: 10px;
	}
}

.content-products-homepage {

	.label_products {
		@include paddings;

		@include susy-breakpoint(920px){
			padding: 0;
		}
	}

	.block_item {
		min-height: 404px;

		@include susy-breakpoint(450px){
			min-height: 350px;
		}

		@include susy-breakpoint(600px){
			min-height: 320px;
		}

		@include susy-breakpoint(920px){
			min-height: 404px;
		}
	}
}
