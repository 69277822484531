html {
	box-sizing: border-box;
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

*, *::before, *::after {
	box-sizing: inherit;
}

img {
	max-width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}
audio,
canvas,
video {
	display: inline-block;
	*display: inline;
	*zoom: 1;
}
audio:not([controls]) {
	display: none;
}

p, html, span, body, div{
	margin  : 0;
	padding : 0;
}

h1 {
    font-family: "OpenSansCond";
	color: #C53613;
    font-size: 32px;
}

p {
    font-family: "OpenSansCond";
    color: #333333;
    font-size: 16px;
    margin-bottom: 16px;
}

.container {
	@include clearfix;
	max-width: 920px;
	margin: 0 auto;
	position: relative;
}

.cover {
	display: none;

	&--active {
		display: block;
		position: fixed;
		z-index: 10;
		width: 100%;
		height: 100%;
		background: $color-dark-alpha;
	}
}
