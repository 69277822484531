.wrapper-product-index{
	float: left;
	width: 100%;
}

.wrapper-product-index img{
	width: 120px;
	float: left;
	margin-right: 14px;
	margin-bottom: 14px;
}

.wrapper-product-index-title{
	font-family: "OpenSansCond";
	font-size: 24px;
	margin-top: 16px;
	margin-bottom: 30px;

	@include susy-breakpoint(920px){
		float: left;
	}
}

.wrapper-product-index-title span{
	font-size: 32px;
	color: rgb( 197, 54, 19 );
}

.wrapper-product-description{
	float: left;
	text-align: left;
}

.wrapper-product-description p{
	margin-bottom: 10px;
	font-size: 16px;
	font-family: "OpenSansCond";
	color: rgb( 51, 51, 51 );
}

.wrapper-product-characteristic{
	width:100%;
}

.wrapper-product-characteristic-left {
	font-size: 20px;
	font-family: "OpenSansCond";
	min-width: 180px;
	max-width: 220px;
	text-align: left;

	@include susy-breakpoint(920px){
		float: left;
		margin-bottom:10px;
	}
}
.wrapper-product-characteristic-left p{
	margin-bottom:8px;
}
.wrapper-product-characteristic-right {
	position: relative;
	text-align: left;
	max-width: 600px;

	@include susy-breakpoint(920px){
		width: 600px;
		float: right;
		left: 20px;
	}
}
.wrapper-product-characteristic-right ul {
	padding-left: 0;

}
.wrapper-product-characteristic-right li{
	list-style: none;
	background: url("../images/market.png")left 8px no-repeat;
	padding-left: 10px;
	font-size: 14px;
	font-family: "OpenSansCond";
	color: rgb( 27, 0, 0 );
}

.wrapper-product-characteristic-right div{
	float: left;
	margin-right: 10px;
	margin-bottom: 20px;
	width: 170px;
}

.wrapper-product-characteristic-right span{
	font-size: 14px;
	font-family: "OpenSansCond";
	color: rgb( 27, 0, 0 );
}

.wrapper-product-characteristic-right-title{
	margin-bottom: 14px;
	float: left;
	width: 100%;

	@include susy-breakpoint(920px){
		margin-left: -8px;
	}
}

.wrapper-product-index .wrapper-product-price{
	display: inline-block;
	width: 138px;
	height: 40px;
	margin-right: 30px;

	@include susy-breakpoint(920px){
		display: block;
		float: left;
		text-align: left;
	}
}


.wrapper-product-index .wrapper-product-price span{
	font-size: 19px!important;
	font-weight: normal!important;
	font-family: "OpenSansCondLightItalic";
	color: #c52013;
	font-style: normal;
	width: 100%;
	text-align: center;
	display: block;
	margin-top: 6px;
}


.wrapper-product-buy {
	display: inline-block;

	@include susy-breakpoint(920px){
		display: block;
	}
}

.wrapper-product-buy input{
	width: 151px;
	height: 44px;
	border: none;
	background: url("../images/background-price-buy.png") no-repeat;
	background-color: #ffffff;
	font-size: 20px;
	font-family: "OpenSansCond";
	color: rgb( 255, 255, 255 );
	text-shadow: 0.5px 0.866px 1px rgb( 0, 1, 1 );
	cursor: pointer;
	float: left;
	margin-right: 30px;
	margin-top: -2px;
	margin-bottom: 30px;
}

.wrapper-product-index a{
	display: block!important;
}

.productfull form {

}

#list_product_image_thumb {
	display: block;
	@include clearfix;
	width: 100% + percentage(14 / 920);

	@include susy-breakpoint(920px){
		width: 100% + percentage(14 / 920);
		margin-left: - percentage(7 / 920);
	}

	.popup-link {
		@include span(50%);
		padding-right: 10px;
		margin-bottom: 14px;

		@include susy-breakpoint(450px){
			@include span(33.33%);
		}

		@include susy-breakpoint(650px){
			@include span(25%);
		}

		@include susy-breakpoint(750px){
			@include span(16.66%);
		}

		@include susy-breakpoint(920px){
			@include span(14.2854%);
			padding-right: percentage(7 / 920);
			padding-left: percentage(7 / 920);
		}
	}

	img {
		width: 100%;
		margin: 0;
	}
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}

.mfp-bottom-bar {
	.mfp-title {
		color: #ccc;
		font-size: 12px;
		line-height: 18px;
	}
}
